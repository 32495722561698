import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Signup.css";

const Signup = ({ onSignup }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("client");

  const [tutorDetails, setTutorDetails] = useState({
    specialization: "",
    salaryExpectation: "",
    subjects: "",
  });

  const [clientDetails, setClientDetails] = useState({
    classSelection: "",
    subject: "",
    location: "",
    preferredTimeSlot: "",
    availability: "",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState(["Math", "English"]);

  const navigate = useNavigate();

  useEffect(() => {
    // Generate class 1 to 12 dynamically and set it to state
    const classList = [];
    for (let i = 1; i <= 12; i++) {
      classList.push(`Class ${i}`);
    }
    setClasses(classList);  // Set classes from 1 to 12
  }, []);

  // Helper functions for validation
  const isValidEmail = (email) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  const isStrongPassword = (password) =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/.test(password);

  const validateForm = () => {
    if (!username || !email || !password) return "Please fill out all required fields.";

    if (!isValidEmail(email)) return "Please enter a valid email.";
    if (!isStrongPassword(password))
      return "Password must be at least 8 characters long, with one number, one uppercase, and one lowercase letter.";

    if (role === "tutor") {
      if (!tutorDetails.specialization || !tutorDetails.salaryExpectation || !tutorDetails.subjects)
        return "Please provide specialization, salary expectation, and subjects for Tutor.";
    } else if (role === "client") {
      if (
        !clientDetails.classSelection ||
        !clientDetails.subject ||
        !clientDetails.location ||
        !clientDetails.preferredTimeSlot ||
        !clientDetails.availability
      )
        return "Please provide all required information for Client.";
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Reset error

    // Validate the form before submission
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    const userData = {
      username,
      email,
      password,
      role,
      ...(role === "tutor" ? tutorDetails : clientDetails),
    };

    setLoading(true);
    try {
      // Send the correct data to the backend
      const response = await axios.post(
        "http://localhost:5000/auth/signup",
        userData,  // Send userData (not formData)
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data.message) {
        alert(response.data.message);
        onSignup(userData);  // Call onSignup with the user data
        if (role === "tutor") navigate("/tutor-dashboard");
        else if (role === "client") navigate("/client-dashboard");
      } else {
        setError("Error during signup, please try again.");
      }
    } catch (error) {
      console.error(error);
      setError("Error during signup, please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signup-container">
      <h2>Sign Up</h2>
      {error && <p className="error-message">{error}</p>}

      <form onSubmit={handleSubmit}>
        {/* Basic Fields */}
        <div>
          <label>Username:</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter a valid email"
          />
          {!isValidEmail(email) && email && <p className="error-message">Please enter a valid email.</p>}
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Password (min 8 characters)"
          />
          {!isStrongPassword(password) && password && <p className="error-message">Password is too weak.</p>}
        </div>
        <div>
          <label>Role:</label>
          <select value={role} onChange={(e) => setRole(e.target.value)}>
            <option value="client">Client</option>
            <option value="tutor">Tutor</option>
          </select>
        </div>

        {/* Tutor Details */}
        {role === "tutor" && (
          <>
            <div>
              <label>Specialization:</label>
              <input
                type="text"
                value={tutorDetails.specialization}
                onChange={(e) => setTutorDetails({ ...tutorDetails, specialization: e.target.value })}
                required
              />
            </div>
            <div>
              <label>Salary Expectation:</label>
              <input
                type="number"
                value={tutorDetails.salaryExpectation}
                onChange={(e) => setTutorDetails({ ...tutorDetails, salaryExpectation: e.target.value })}
                required
              />
            </div>
            <div>
              <label>Subjects You Can Teach:</label>
              <input
                type="text"
                value={tutorDetails.subjects}
                onChange={(e) => setTutorDetails({ ...tutorDetails, subjects: e.target.value })}
                required
              />
            </div>
          </>
        )}

        {/* Client Details */}
        {role === "client" && (
          <>
            <div>
              <label>Class:</label>
              <select
                value={clientDetails.classSelection}
                onChange={(e) => setClientDetails({ ...clientDetails, classSelection: e.target.value })}
                required
              >
                <option value="">Select Class</option>
                {classes.length > 0 ? (
                  classes.map((classOption, index) => (
                    <option key={index} value={classOption}>
                      {classOption}
                    </option>
                  ))
                ) : (
                  <option>No classes available</option>
                )}
              </select>
            </div>
            <div>
              <label>Subject:</label>
              <select
                value={clientDetails.subject}
                onChange={(e) => setClientDetails({ ...clientDetails, subject: e.target.value })}
                required
              >
                <option value="">Select Subject</option>
                {subjects.map((subject, index) => (
                  <option key={index} value={subject}>
                    {subject}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Location:</label>
              <input
                type="text"
                value={clientDetails.location}
                onChange={(e) => setClientDetails({ ...clientDetails, location: e.target.value })}
                required
              />
            </div>
            <div>
              <label>Preferred Time Slot:</label>
              <input
                type="text"
                value={clientDetails.preferredTimeSlot}
                onChange={(e) => setClientDetails({ ...clientDetails, preferredTimeSlot: e.target.value })}
                required
              />
            </div>
            <div>
              <label>Availability:</label>
              <input
                type="text"
                value={clientDetails.availability}
                onChange={(e) => setClientDetails({ ...clientDetails, availability: e.target.value })}
                required
              />
            </div>
          </>
        )}

        <button type="submit" disabled={loading}>
          {loading ? "Signing Up..." : "Sign Up"}
        </button>
      </form>
    </div>
  );
};

export default Signup;
