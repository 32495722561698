import React from "react";
import "./Header.css"; // Assuming you have a CSS file for styling
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is installed and imported
import { Link } from "react-router-dom";
import sliderImage1 from "./images/istockphoto-1227389686-2048x2048.jpg";

const Header = () => {
  const sliderData = [
    {
      image: sliderImage1,
      title: "A Perfect Learning Center",
      subtitle: "For Your Kids",
      description:
        "Greatness begins with the right guidance – discover a tutor who will awaken your child's true potential.",
    },
  ];

  return (
    <section className="slider_section position-relative">
      <div className="container-fluid">
        <div className="static-slider">
          {sliderData.map((item, index) => (
            <div
              key={index}
              className="static-slider-item"
              style={{
                backgroundImage: `url(${item.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "100vh",
              }}
            >
              <div className="row h-100 justify-content-center align-items-center">
                <div className="col-md-6 text-center">
                  <div className="detail-box">
                    <h1>
                      {item.title} <br />
                      <span>{item.subtitle}</span>
                    </h1>
                    <p>{item.description}
                    </p>
                    <div className="btn-box">
                      <Link to="/About2" className="btn-1">
                        Read More
                      </Link>
                      <Link to="/Contact" className="btn-2">
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Header;
