// src/components/TutorDashboard.js

import React, { useState, useEffect } from 'react';
import './TutorDashboard.css'; // If you want to add styles

const TutorDashboard = () => {
  const [sessions, setSessions] = useState([]);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch tutor's sessions and requests (replace with actual API calls)
    const fetchTutorData = async () => {
      try {
        // Simulate fetching sessions and requests
        const sessionData = [
          { id: 1, student: 'John Doe', subject: 'Math', date: '2024-11-14' },
          { id: 2, student: 'Jane Smith', subject: 'English', date: '2024-11-15' },
        ];
        const requestData = [
          { id: 1, student: 'Alex Brown', subject: 'Science', status: 'Pending' },
          { id: 2, student: 'Emily White', subject: 'History', status: 'Accepted' },
        ];

        setSessions(sessionData);
        setRequests(requestData);
      } catch (error) {
        console.error('Error fetching tutor data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTutorData();
  }, []);

  return (
    <div className="dashboard-container">
      <h1>Welcome to the Tutor Dashboard!</h1>
      <p>Here you can manage your tutoring sessions, view student requests, and track your progress.</p>

      {loading ? (
        <p>Loading data...</p>
      ) : (
        <>
          <div className="section">
            <h2>Your Sessions</h2>
            {sessions.length === 0 ? (
              <p>No upcoming sessions.</p>
            ) : (
              <ul>
                {sessions.map((session) => (
                  <li key={session.id}>
                    {session.student} - {session.subject} on {session.date}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="section">
            <h2>Student Requests</h2>
            {requests.length === 0 ? (
              <p>No pending requests.</p>
            ) : (
              <ul>
                {requests.map((request) => (
                  <li key={request.id}>
                    {request.student} - {request.subject} [{request.status}]
                  </li>
                ))}
              </ul>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TutorDashboard;
