import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Public Routes Components
import Home from "./Home";
import About2 from "./About2";
import Contact from "./Contact";
import Services from "./Services";
import Programs from "./Programs";

// Auth Routes and Dashboard Components
import Login from "./components/Login";
import Signup from "./components/Signup";
import Dashboard from "./Dashboard"; // Admin Dashboard
import TutorDashboard from "./components/TutorDashboard"; // Tutor Dashboard
import StudentDashboard from "./components/StudentDashboard"; // Student Dashboard

// Error Boundary Component
const ErrorFallback = ({ error }) => (
  <div role="alert" style={{ textAlign: "center", marginTop: "20px" }}>
    <h2>Something went wrong!</h2>
    <pre>{error.message}</pre>
  </div>
);

const ProtectedRoute = ({ element, requiredRole, fallbackPath = "/login" }) => {
  const userRole = localStorage.getItem("userRole");
  return userRole === requiredRole ? element : <Navigate to={fallbackPath} />;
};

const App = () => {
  const [userRole, setUserRole] = useState(null);

  // Load role from localStorage on app start
  useEffect(() => {
    const savedRole = localStorage.getItem("userRole");
    if (savedRole) setUserRole(savedRole);
  }, []);

  // Handle login
  const handleLogin = (role) => {
    setUserRole(role);
    localStorage.setItem("userRole", role);
  };

  // Handle logout
  const handleLogout = () => {
    setUserRole(null);
    localStorage.removeItem("userRole");
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* Redirect logged-in users to their respective dashboard */}
        <Route
          path="/"
          element={
            userRole ? <Navigate to={`/${userRole}-dashboard`} /> : <Home />
          }
        />

        {/* Public Routes */}
        <Route path="/About2" element={<About2 />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Programs" element={<Programs />} />

        {/* Login and Signup Routes */}
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/signup" element={<Signup />} />

        {/* Protected Routes */}
        {/* Admin Dashboard */}
        <Route
          path="/admin-dashboard"
          element={<ProtectedRoute element={<Dashboard />} requiredRole="admin" />}
        />

        {/* Tutor Dashboard */}
        <Route
          path="/tutor-dashboard"
          element={<ProtectedRoute element={<TutorDashboard />} requiredRole="tutor" />}
        />

        {/* Student Dashboard */}
        <Route
          path="/student-dashboard"
          element={<ProtectedRoute element={<StudentDashboard />} requiredRole="student" />}
        />

        {/* Services (only accessible by admin) */}
        <Route
          path="/Services"
          element={<ProtectedRoute element={<Services />} requiredRole="admin" />}
        />

        {/* Catch-all Route for undefined paths */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      {/* Logout Button */}
      {userRole && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button
            onClick={handleLogout}
            style={{
              padding: "10px 20px",
              backgroundColor: "#ff4d4d",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Logout
          </button>
        </div>
      )}
    </BrowserRouter>
  );
};

export default App;
